import { Box, Heading, Image, Stack, useTheme, VStack } from "@chakra-ui/react"
import React from "react"
import YellowHeading from "../../../components/common/YellowHeading"
import Section from "wildComponents/Section"

function GetItDone() {
  const { variables } = useTheme()
  const { containerPaddingX } = variables

  return (
    <Section
      bg="dark.default"
      containerSize="xl"
      minH="100vh"
      containerStyles={{ pt: 0, pb: containerPaddingX }}
    >
      <Stack
        h="full"
        w="full"
        align="center"
        direction={["column", "column", "column", "row"]}
        zIndex="1"
        spacing={6}
      >
        <VStack spacing={12} flex="1" align="flex-start">
          <Heading className="jumbo" color="white">
            Get it all{" "}
            <span>
              done
              <YellowHeading> here</YellowHeading>
            </span>
          </Heading>
          <Heading as="h3" className="thin-h3" color="white" flex="1">
            Unlike other free zones in Dubai, we provide a broad range of
            support services that meet your needs beyond the business setup
            stage – from opening your corporate banking account, to registering
            for VAT and managing your accounting. Our team of world-class
            consultants are on site and will get it all done.
          </Heading>
        </VStack>
        <Box flex="1" py={[40, 40, 40, 0]}>
          <Image
            src="https://res.cloudinary.com/wild-creative/image/upload/v1653637129/DUQE/Vectors/Corp_D_hamrzw.png"
            position="absolute"
            maxW={["100%", "100%", "60vw", "45vw", "40vw", "40vw", "45vw"]}
            right="0"
            bottom={["-9%", "-9%", "-12%", "-20%", "-15%", "-15%", "-20%"]}
            data-speed="0.9"
          />
        </Box>
      </Stack>
    </Section>
  )
}

export default GetItDone
