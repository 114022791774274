import React from "react"
import Layout from "wildComponents/Layout"
import { graphql } from "gatsby"
import { Seo } from "wildComponents/seo/components"
import Hero from "../sections/page-specific/corporate-services/Hero"
import GetItDone from "../sections/page-specific/corporate-services/GetItDone"
import Benefits from "../sections/page-specific/home/Benefits"
import Apply from "../sections/page-specific/corporate-services/Apply"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function CorporateServices(props) {
  return (
    <Layout>
      <Hero />
      <GetItDone />
      <Benefits />
      <Apply />
    </Layout>
  )
}

export default CorporateServices

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`
